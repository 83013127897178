// storage.js

// Utility to get data from storage (local/session)
const getStorage = (storage, key) => {
    try {
      const storedValue = storage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : null; // Parse JSON if available, or return null
    } catch (error) {
      console.error(`Error getting storage key "${key}":`, error);
      return null; // Return null in case of error
    }
  };
  
  // Utility to set data in storage (local/session)
  const setStorage = (storage, key, value) => {
    try {
      const valueToStore = JSON.stringify(value); // Convert value to JSON string
      storage.setItem(key, valueToStore);
    } catch (error) {
      console.error(`Error setting storage key "${key}":`, error);
    }
  };
  
  // Utility to remove data from storage (local/session)
  const removeStorage = (storage, key) => {
    try {
      storage.removeItem(key);
    } catch (error) {
      console.error(`Error removing storage key "${key}":`, error);
    }
  };
  
  // Utility to clear all data in storage (local/session)
  const clearStorage = (storage) => {
    try {
      storage.clear();
    } catch (error) {
      console.error("Error clearing storage:", error);
    }
  };
  
  // Local Storage Functions
  export const getLocalStorage = (key) => getStorage(localStorage, key);
  export const setLocalStorage = (key, value) => setStorage(localStorage, key, value);
  export const removeLocalStorage = (key) => removeStorage(localStorage, key);
  export const clearLocalStorage = () => clearStorage(localStorage);
  
  // Session Storage Functions
  export const getSessionStorage = (key) => getStorage(sessionStorage, key);
  export const setSessionStorage = (key, value) => setStorage(sessionStorage, key, value);
  export const removeSessionStorage = (key) => removeStorage(sessionStorage, key);
  export const clearSessionStorage = () => clearStorage(sessionStorage);
  