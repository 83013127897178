import React, { useEffect, useState, useContext, useRef } from "react";
import { Tree, Button, Tooltip } from "antd";
import ConfirmationAlert from "../components/ConfirmationAlert";
import {
  PlusCircleOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import CommonModal from "../components/Modal/CommonModal.jsx";
import { Context } from "../store/Store";

const StyledContainer = styled.div`
  .ant-tree {
    letter-spacing: 0.3px !important;
  }
  .ant-tree .ant-tree-treenode {
    padding: 0 0 10px 0 !important;
  }
  .title-container {
    .title {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span { 
        width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .add-delete-container {
      width: 20%;
    }
  }
    .ant-tree-node-content-wrapper,  ant-tree-node-content-wrapper-normal{
        overflow: hidden;
    }

`;

const { TreeNode } = Tree;

// MenuItem Component
const MenuItem = ({
  menuItem,
  isEdittedPage,
  setIsVisibleModal,
  setUpdateParentList,
  viewMode,
  onSelectMenuItem,
  onSubMenuClick,
  onRemoveSubMenu,
}) => {
  const textRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsOverflow(element.scrollWidth > element.clientWidth);
    }
  }, [menuItem.title]);

  const handleMenuItemClick = () => {
    if (isEdittedPage && !viewMode) {
            setIsVisibleModal(true);
        }
        else {
            onSelectMenuItem(menuItem);
            menuItem.pageCode && setUpdateParentList(menuItem);
        }
  };

  return (
    <div className="title-container" onClick={handleMenuItemClick}>
      <span className="title" ref={textRef}>
        {isOverflow ? (
          <Tooltip title={menuItem.title} color="var(--secondary-color)" placement="right">
            {menuItem.title}
          </Tooltip>
        ) : (
          menuItem.title
        )}
      </span>
      <div className="add-delete-container">
        {menuItem.pageCode && !viewMode && (
          <>
            <PlusCircleOutlined
              className="add-sub-icon add-sub-button"
              onClick={() => {
                if (!isEdittedPage) onSubMenuClick(menuItem);
              }}
            />
            {isEdittedPage ? (
              <DeleteOutlined
                className="add-sub-icon add-sub-button"
                style={{ width: "18px", height: "18px", zIndex: "9999", cursor: "pointer" }}
                onClick={(e) => e.preventDefault()} // Prevent deletion if editing
              />
            ) : (
              <ConfirmationAlert
                action={() => onRemoveSubMenu(menuItem, menuItem.title)}
                title="Are you sure you want to delete this menu?"
              >
                <DeleteOutlined
                  className="add-sub-icon add-sub-button"
                  style={{ width: "18px", height: "18px", zIndex: "9999", color: "blue", cursor: "pointer" }}
                />
              </ConfirmationAlert>
            )}
          </>
        )}
      </div>
    </div>
  );
};

// Main Component
const AdminTreeMenu = ({
  menuItems,
  onSubMenuClick,
  onRemoveSubMenu,
  onSelectMenuItem,
  setUpdateParentList,
  showConfirmModal = false,
  updateModalStatus,
  viewMode = false,
}) => {
  const [deviceState, dispatch] = useContext(Context);
  const [isEdittedPage, setIsEdittedPage] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(showConfirmModal ?? false);

  const { documents = {} } = deviceState ?? {};
  const { isPageEdit, onSave, onDestroy } = documents;

  useEffect(() => {
    setIsEdittedPage(isPageEdit);
  }, [deviceState, isPageEdit]);

  useEffect(() => {
    setIsVisibleModal(showConfirmModal);
  }, [showConfirmModal]);

  const customSwitcherIcon = ({ isLeaf, expanded }) => {
    if (isLeaf) {
      return null; // Return null for leaf nodes without an icon
    }
    return expanded ? <CaretDownOutlined /> : <CaretRightOutlined />;
  };

  const handleSave = () => {
    onSave();
    setIsVisibleModal(false);
    updateModalStatus(false);
  };

  const handleDiscard = () => {
    onDestroy();
    setIsVisibleModal(false);
    updateModalStatus(false);
  };

  const renderMenuItems = (items, onSubMenuClick, onRemoveSubMenu, onExpand, parentKey = "menu") => {
    return items.map((menuItem, index) => (
      <TreeNode
        switcherIcon={customSwitcherIcon}
        key={`${parentKey}-${index}`}
        title={
          <MenuItem
            menuItem={menuItem}
            setUpdateParentList={setUpdateParentList}
            setIsVisibleModal={setIsVisibleModal}
            isEdittedPage={isEdittedPage}
            viewMode={viewMode}
            onSelectMenuItem={onSelectMenuItem}
            onSubMenuClick={onSubMenuClick}
            onRemoveSubMenu={onRemoveSubMenu}
          />
        }
        selectable={false}
        onExpand={(expanded, nodeInfo) => onExpand(expanded, nodeInfo, menuItem)}
      >
        {menuItem.children && menuItem.children.length > 0
          ? renderMenuItems(menuItem.children, onSubMenuClick, onRemoveSubMenu, onExpand, `${parentKey}-${index}`)
          : null}
      </TreeNode>
    ));
  };

  const handleExpand = (expandedKeys, { expanded }, menuItem) => {
    if (expanded) {
      // Logic for expanded menu
    }
  };

  return (
    <StyledContainer>
      <Tree
        defaultExpandAll
        showLine
        onExpand={(expandedKeys, info) => handleExpand(expandedKeys, info, menuItems)}
      >
        {renderMenuItems(menuItems, onSubMenuClick, onRemoveSubMenu, handleExpand)}
      </Tree>

      <CommonModal
        title="Documents"
        isMeetingModal={isVisibleModal}
        setIsMeetingModal={setIsVisibleModal}
        onHandleCancel={() => updateModalStatus(false)}
        width={530}
        footer={[
          <Button key="discard" onClick={handleDiscard}>
            Discard
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
        ]}
      >
        <p style={{ fontSize: "1rem" }}>Do you want to save changes before switching pages?</p>
      </CommonModal>
    </StyledContainer>
  );
};

export default AdminTreeMenu;
