import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from '@emotion/styled';

const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    rgba(255, 255, 255, 0.98),
    rgba(255, 255, 255, 0.96)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  animation: fadeIn 0.3s ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const SpinnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    border-radius: 50%;
    border: 3px solid #f0f7ff;
  }

  &::after {
    content: '';
    position: absolute;
    width: calc(100% + 15px);
    height: calc(100% + 15px);
    border-radius: 50%;
    border: 2px solid #e6f0fd;
  }
`;

const MessageContainer = styled.div`
  text-align: center;
`;

const MainMessage = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: 0.75rem;
  letter-spacing: -0.01em;
`;

const SubMessage = styled.div`
  font-size: 0.875rem;
  color: #6b7280;
  letter-spacing: -0.01em;
  position: relative;
  padding-bottom: 3px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 2px;
    background: ${props => props.color};
    border-radius: 2px;
    opacity: 0.6;
  }
`;

const ProgressDots = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 1rem;

  .dot {
    width: 4px;
    height: 4px;
    background-color: #d1d5db;
    border-radius: 50%;
    animation: dotPulse 1.5s infinite;

    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }

  @keyframes dotPulse {
    0%, 100% {
      opacity: 0.4;
      transform: scale(1);
    }
    50% {
      opacity: 1;
      transform: scale(1.1);
    }
  }
`;

const Loader = ({
  loading = true,
  size = 45,
  color = '#3b82f6',
  message = 'Processing Your Request',
  subMessage = 'Please wait while we prepare your data',
}) => {
  if (!loading) return null;

  return (
    <LoaderOverlay>
      <LoaderContainer>
        <SpinnerContainer>
          <ClipLoader
            color={color}
            loading={loading}
            size={size}
            aria-label="Loading Spinner"
            speedMultiplier={0.9}
          />
        </SpinnerContainer>
        
        <MessageContainer>
          <MainMessage>{message}</MainMessage>
          <SubMessage color={color}>{subMessage}</SubMessage>
          <ProgressDots>
            <div className="dot" />
            <div className="dot" />
            <div className="dot" />
          </ProgressDots>
        </MessageContainer>
      </LoaderContainer>
    </LoaderOverlay>
  );
};

export default Loader;